import dayjs from 'dayjs';

import { CustomizeData } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/customize/types';

import { stringConvertHyperlink } from './string';

export const splitLines = (str: string): string[] | undefined => {
  const arr = str?.split(/\r?\n/);
  return arr?.filter((str) => str !== '');
};

export const endKey = '##Source##';
export const errorKey = '##Error##';

export const clearReply = (message: string): string => {
  switch (true) {
    case message.includes('AI: '):
      return message.replace('AI: ', '');
    case message.includes('AI Assistant: '):
      return message.replace('AI Assistant: ', '');
    default:
      return message;
  }
};

export const cleanAndGetErrorMessage = (str: string): string => {
  try {
    let cleanStr = str?.replace(`${errorKey}:`, '');
    // trim last . in string
    cleanStr = cleanStr?.replace(/\.$/, '');
    return stringConvertHyperlink(cleanStr);
  } catch (e) {
    return undefined;
  }
};

type ContentFilter = {
  filtered: boolean;
  severity: 'safe' | 'low' | 'medium' | 'high';
};
type ContentFilterResult = {
  hate: ContentFilter;
  self_harm: ContentFilter;
  sexual: ContentFilter;
  violence: ContentFilter;
};

const severityOrder = {
  safe: 0,
  low: 1,
  medium: 2,
  high: 3,
};

export const getContentFilterErrorMessage = (
  filterResult: ContentFilterResult,
): string => {
  const messages = {
    default:
      "I apologize, but I'm unable to provide assistance with this topic due to our guidelines. How can I support you with our services?",
    hate: 'I apologize, but I am afraid I cannot help you in this. How can I assist you with our services?',
    self_harm:
      "I'm genuinely sorry to hear that but I'm not equipped to provide the necessary help.",
    sexual:
      "I'm sorry, but I cannot engage in discussions on this topic. How can I assist you with our services?",
    violence:
      'I regret to inform you that I cannot assist with that. Is there anything I can help you with?',
  };

  if (!filterResult) return messages.default;

  const filtered = Object.keys(filterResult).filter(
    (key) => filterResult[key].filtered,
  );

  if (filtered.length === 0) {
    return messages.default;
  }

  const results = filtered.map((key) => ({
    key: key as keyof ContentFilterResult,
    severity: filterResult[key].severity,
  }));

  const result = results.reduce((acc, curr) => {
    if (severityOrder[curr.severity] > severityOrder[acc.severity]) {
      return curr;
    }
    return acc;
  }, results[0]);

  return `${messages[result.key]}`;
};

type GenerateInitialMessagesWithDateReturn = {
  text: string;
  createdAt: Date;
}[];
export const generateInitialMessagesWithDate = (
  customize: CustomizeData,
): GenerateInitialMessagesWithDateReturn | null => {
  if (customize?.removeInitialMessage) {
    return null;
  }

  const initialMessagesString = splitLines(customize?.initialMessage) || [];

  const initialMessages = initialMessagesString
    ?.reverse()
    ?.map((message, index) => {
      return {
        text: message,
        createdAt: dayjs()
          .subtract(index + 1, 'second')
          .toDate(),
      };
    })
    ?.filter(Boolean);

  return initialMessages;
};
